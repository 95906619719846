import { getSectionSpacing, Section } from '@elseu/sdu-titan-web-commerce';
import type { SectionProps } from 'components/Section';
import type { SectionForm as ContentfulSectionForm } from 'generated/graphql';
import dynamic from 'next/dynamic';
import React, { useRef } from 'react';
import styled, { css } from 'styled-components';

import type { GenialFormType } from './genial-trial/GenialFormContainer';

const EaadEmbed = dynamic(() => import('./eaad/EaadEmbed').then((mod) => mod.EaadEmbed), {
  ssr: false,
});

const HeyFlowForm = dynamic(() => import('./heyflow/HeyFlow').then((mod) => mod.HeyFlowForm), {
  ssr: false,
});

const MicrosoftBooking = dynamic(
  () => import('./microsoft-bookings/MicrosoftBooking').then((mod) => mod.MicrosoftBooking),
  {
    ssr: false,
  },
);

const GenialFormContainer = dynamic(() =>
  import('./genial-trial/GenialFormContainer').then((mod) => mod.GenialFormContainer),
);

type SectionFormProps = Pick<ContentfulSectionForm, 'snippet' | 'name' | 'kind'> & SectionProps;

export enum SectionFormKind {
  EAAD_NEWSLETTER = 'eaad-newsletter',
  EAAD_LEAD_GENERATION = 'eaad-lead-generation',
  HEY_FLOW = 'hey-flow',
  MICROSOFT_BOOKINGS = 'microsoft-bookings',
  GENIAL = 'genial',
}

const StyledSectionForm = styled(Section)`
  ${({ theme }) => css`
    ${getSectionSpacing({ theme, xPadding: 8, yPadding: 8, maxWidth: '1200px' })}
  `}
`;

const getContents = ({ kind, snippet }: { kind: string; snippet: string }) => {
  switch (kind) {
    case SectionFormKind.EAAD_NEWSLETTER:
    case SectionFormKind.EAAD_LEAD_GENERATION:
      return <EaadEmbed kind={kind} snippet={snippet} />;
    case SectionFormKind.HEY_FLOW:
      return <HeyFlowForm snippet={snippet} />;
    case SectionFormKind.MICROSOFT_BOOKINGS:
      return <MicrosoftBooking snippet={snippet} />;
    case SectionFormKind.GENIAL:
      return <GenialFormContainer type={snippet as GenialFormType} />;
    default:
      return null;
  }
};

/**
 * The SectionForm component renders a form based on the given 'kind' prop.
 * It utilizes custom hooks to manage form-specific logic for 'hey-flow' and 'eaad' forms.
 * @component
 * @example
 * <SectionForm snippet={snippet} name={name} id={id} background={background} kind={kind} />
 *
 */
export const SectionForm: React.FC<SectionFormProps> = ({
  snippet,
  name,
  id,
  background,
  kind,
}) => {
  const sectionFormRef = useRef<HTMLDivElement>(null);

  if (!snippet || !kind) {
    return null;
  }

  const contents = getContents({ kind, snippet });

  if (!contents) {
    return null;
  }

  return (
    <>
      <StyledSectionForm
        name={name}
        reference={id}
        background={background as 'sand' | 'white'}
        ref={sectionFormRef}
      >
        {contents}
      </StyledSectionForm>
    </>
  );
};
