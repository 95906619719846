import { PageSkeleton } from '@elseu/sdu-titan-web-commerce';
import { captureException } from '@sentry/nextjs';
import { Footer } from 'components/Footer';
import { Navigation } from 'components/Navigation';
import { Page } from 'components/Page';
import { SectionBrands } from 'components/SectionBrands';
import { SectionCard } from 'components/SectionCard';
import { SectionContent } from 'components/SectionContent';
import { SectionFaq } from 'components/SectionFaq';
import { SectionFeature } from 'components/SectionFeature';
import { SectionForm } from 'components/SectionForm';
import { SectionHeader } from 'components/SectionHeader';
import { SectionHighlight } from 'components/SectionHighlight';
import { SectionLegacyContent } from 'components/SectionLegacyContent';
import { SectionNavigation } from 'components/SectionNavigation';
import { SectionPeople } from 'components/SectionPeople';
import { SectionProduct } from 'components/SectionProduct';
import { SectionQuickLink } from 'components/SectionQuickLink';
import { SectionQuote } from 'components/SectionQuote';
import { SectionVideo } from 'components/SectionVideo';
import { BLOG_PREFIX, BLOGS_PREFIX } from 'constants/blog';
import type {
  Footer as ContentfulFooter,
  FooterCollection,
  Navigation as ContentfulNavigation,
  NavigationCollection,
  Page as ContentfulPage,
  PageCollection,
  Toast as ContentfulToast,
  ToastCollection,
} from 'generated/graphql';
import { print } from 'graphql';
import GetPageByCategoryAndSlug from 'graphql/getPageByCategoryAndSlug.gql';
import compress from 'graphql-query-compress';
import type { GetStaticPaths, GetStaticProps, InferGetStaticPropsType } from 'next';
import { useRouter } from 'next/router';
import { extractSlugAndCategory } from 'utilities/extractSlugAndCategory';
import { findRedirect, getRedirectCollection } from 'utilities/findRedirect';
import { contentfulGraphqlClient } from 'utilities/graphqlClient';
import { addLegacyContent } from 'utilities/legacyContent';

export type PageProps = Pick<
  ContentfulPage,
  'seo' | 'slug' | 'category' | 'breadcrumb' | 'sectionsCollection'
> & {
  navigation?: ContentfulNavigation | null;
  footer?: ContentfulFooter | null;
  toast?: ContentfulToast | null;
  preview?: boolean;
};

/**
 * Mapping between Contentful data and components.
 *
 * @type {Object}
 */
const componentMapping = {
  SectionBrands,
  SectionCard,
  SectionContent,
  SectionFaq,
  SectionFeature,
  SectionForm,
  SectionHeader,
  SectionHighlight,
  SectionNavigation,
  SectionPeople,
  SectionProduct,
  SectionQuickLink,
  SectionQuote,
  SectionVideo,
  SectionLegacyContent,
};

/**
 * The page component that renders the content for a given slug and category.
 *
 * @param {Object} props - The props for the component.
 * @param {Object} props.seo - The SEO data for the page.
 * @param {string} props.slug - The slug for the page.
 * @param {string} props.category - The category for the page.
 * @param {boolean} props.breadcrumb - Whether to show the breadcrumb on the page.
 * @param {Object} props.sectionsCollection - The sections for the page.
 * @param {Object} props.navigation - The navigation links for the page.
 * @param {Object} props.footer - The footer data for the page.
 * @param {Object} props.toast - The toast data for the page.
 * @param {boolean} props.redirected - Whether the page was redirected.
 * @param {boolean} props.preview - Whether the page is in preview mode.
 * @returns {JSX.Element} - The page component.
 */
const SduPage = ({
  seo,
  slug,
  category,
  breadcrumb,
  sectionsCollection,
  navigation,
  footer,
  toast,
  preview,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const { isFallback } = useRouter();

  if (isFallback) {
    return <PageSkeleton />;
  }

  const sections = sectionsCollection?.items.map((section) => {
    if (!section.__typename) return null;

    const Component = componentMapping[section.__typename];

    return <Component key={section.sys.id} id={section.sys.id} {...section} />;
  });

  return (
    <Page
      seo={seo}
      slug={slug}
      category={category}
      navigation={<Navigation {...navigation} />}
      footer={<Footer hasBreadcrumb={breadcrumb} {...footer} />}
      sections={sections}
      toast={toast}
      isPreview={preview}
    />
  );
};

/**
 * Collect all data for the requested Sdu Page from Contentful and return notFound when not a valid path
 *
 * @param {Object} context - The context for the function.
 * @param {string[]} context.params.slug - The slug for the page.
 * @returns {Promise<Object>} - The static props for the page.
 */
export const getStaticProps: GetStaticProps<PageProps> = async ({ preview = false, params }) => {
  /** The configuration to return when nu page has been found */
  const notFound: {
    notFound: true;
  } = {
    notFound: true,
  };

  /** Remove the placeholder root url from rendering */
  if (params?.slug?.includes('root')) {
    return notFound;
  }

  /** Extract the slug and category from the params */
  const { slug, category } = extractSlugAndCategory(params?.slug);

  /** Return the Not Found page when no slug and category are provided */
  if (!slug && !category) {
    return notFound;
  }

  /** Query Contentful to get all data for the requested page */
  try {
    /** Check if a redirect is needed */
    const redirectCollection = await getRedirectCollection();
    const redirect = await findRedirect(redirectCollection, { slug, category });

    if (redirect) {
      return {
        props: {},
        redirect: {
          destination: redirect.destination,
          permanent: redirect.permanent,
        },
      };
    }

    const {
      pageCollection,
      footerCollection,
      navigationCollection,
      toastCollection,
    }: {
      pageCollection?: PageCollection;
      footerCollection?: FooterCollection;
      navigationCollection?: NavigationCollection;
      toastCollection?: ToastCollection;
    } = await contentfulGraphqlClient({
      preview,
    }).request(compress(print(GetPageByCategoryAndSlug)), {
      preview,
      category,
      slug: slug.toLowerCase(),
    });
    let page = pageCollection?.items[0];
    const footer = footerCollection?.items[0];
    const navigation = navigationCollection?.items[0];
    const toast = toastCollection?.items[0] || null;

    if (!page && category === BLOG_PREFIX) {
      return {
        redirect: {
          destination: BLOGS_PREFIX,
          permanent: false,
        },
      };
    }

    /** Add Legacy content data when needed */
    if (page) {
      page = await addLegacyContent(page);
    }

    return page
      ? {
          props: {
            preview,
            ...page,
            footer: page.footer ?? footer ?? null,
            navigation: page.navigation ?? navigation ?? null,
            toast,
          },
          revalidate: 60,
        }
      : notFound;
  } catch (error) {
    console.error(error);
    captureException(error);

    return notFound;
  }
};

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [],
    fallback: true,
  };
};

export default SduPage;
