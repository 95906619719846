import { SectionBrands as DSSectionBrands } from '@elseu/sdu-titan-web-commerce';
import { Image } from 'components/Image';
import type { SectionProps } from 'components/Section';
import type { SectionBrands as ContentfulSectionBrands } from 'generated/graphql';

type SectionBrandsProps = Pick<
  ContentfulSectionBrands,
  'brandsCollection' | 'name' | 'title' | 'description'
> &
  SectionProps;

/**
 * SectionBrands component.
 *
 * @param {SectionBrandsProps} props - Properties passed to the component.
 * @returns {React.FC} Section that displays a collection of brands.
 */
export const SectionBrands: React.FC<SectionBrandsProps> = ({
  brandsCollection,
  name,
  title,
  description,
  id,
  background,
}) => (
  <DSSectionBrands
    name={name}
    reference={id}
    title={title}
    description={description}
    background={background as 'sand' | 'white'}
  >
    {brandsCollection?.items.map((brand) => {
      if (
        !brand.asset?.width ||
        !brand.asset.height ||
        isNaN(brand.asset.width) ||
        isNaN(brand.asset.height)
      ) {
        return null;
      }

      /**
       * We want to keep the aspect ratio of the image, so we calculate the width based on the height.
       */
      const height = 64;
      const width = Math.round(height * (brand.asset.width / brand.asset.height));

      return (
        <Image
          key={brand.asset.url}
          asset={brand.asset}
          alt={brand.alt}
          height={height}
          width={width}
        />
      );
    })}
  </DSSectionBrands>
);
